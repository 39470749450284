import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {KeycloakOptions} from 'keycloak-angular/lib/core/interfaces/keycloak-options';
import {environment} from '../environments/environment';
import {lastValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  static settings: IAppConfig;

  private http = inject(HttpClient);

  constructor() {}

  load() {
    const jsonFile = `assets/config.json`;

    return new Promise<IAppConfig>((resolve) => {
      lastValueFrom(this.http.get<IAppConfig>(jsonFile)).then((response) => {
        AppConfigService.settings = (response as IAppConfig);
        console.log('Config Loaded');
        if (!environment.production) {
          console.log(AppConfigService.settings);
        }
        resolve(AppConfigService.settings);
      }).catch(() => {
        // auch Datei nicht vorhanden ist ein erfolgreiches Ergebnis => ohne Inhalt
        resolve(AppConfigService.settings);
      });
    });
  }
}

export interface IAppConfig {
  apiUrl: string;
  eigenleistung_enabled: boolean | string;  // Für Umwandlung aus docker-compose file
  keycloakOptions: KeycloakOptions;
}
