// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {KeycloakOptions} from 'keycloak-angular/lib/core/interfaces/keycloak-options';

const keycloakOptions: KeycloakOptions = {
  config: {
    url: 'http://172.16.1.20:8180/auth/',
    realm: 'Immodat',
    clientId: 'client-01',
  },
  initOptions: {
    onLoad: 'login-required',
    checkLoginIframe: false
  },
  loadUserProfileAtStartUp: false
};


export const environment = {
  production: false,
  apiUrl: 'http://localhost:8822/api',
  eigenleistung_enabled: true,
  keycloakOptions
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
