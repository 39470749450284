import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Rechtsfall} from '../entity/rechtsfall';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private rechtsfallSource = new BehaviorSubject<Rechtsfall>(undefined);
  currentRechtsfall$ = this.rechtsfallSource.asObservable();

  constructor() {}

  setRechtsfall(rechtsfall: Rechtsfall) {
    this.rechtsfallSource.next(rechtsfall);
  }
}

